import React from 'react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const Breadcrumbs = ({ fields }) => {
  const links = [];
  const mapLink = (item) => {
    if (item.navigationTitle && !item.hideFromNavigation.boolValue) {
      links.push({
        text: item.navigationTitle.value || item.title.value,
        path: item.navigationLink.url || item.url,
        id: item.id,
      });
    }
    if (item.parent) {
      mapLink(item.parent);
    }
  };

  mapLink(fields.data.item);

  return (
    <nav className="l-padding breadcrumbs">
      <ul>
        {links.reverse().map((link, index) => (
          <li key={`${link.id}-${index}`}>
            {index < links.length - 1 ? (
              <a href={link.path}>{link.text}</a>
            ) : (
              <span className="is-active">{link.text}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

Breadcrumbs.propTypes = PropTypes.SitecoreItem.inject({
  data: PropTypes.shape({
    item: PropTypes.shape({
      ...PropTypes.GraphQL.basePage,
      //Recurses up the tree, this is too difficult to show in propTypes
      parent: PropTypes.any,
    }),
  }),
});

Breadcrumbs.defaultProps = DefaultProps.SitecoreItem.inject({
  data: {
    item: {},
  },
});

export default Breadcrumbs;
